<template>
  <b-container class="col-md-6 mt-3">
    <b-card v-if="!profileRemoved">
      <b-card-title>{{ $t('titles.profile_removal') }}</b-card-title>
      <p>{{ $t('questions.are_you_sure_you_want_to_delete_your_profile') }}</p>
      <p>{{ $t('messages.we_are_planning_great_features_to_come') }}</p>
      <p>
        {{ $t('messages.keep_in_mind_that_if_you_have_orders_some_data_may') }}
      </p>
      <b-card-footer class="d-flex justify-content-between">
        <b-link :to="{name: 'user.account', params: {section: 'profile'}}">{{ $t('buttons.no_take_me_to_my_profile') }}
        </b-link>
        <b-button variant="danger" @click="confirmProfileRemoval">{{ $t('buttons.yes_delete_my_profile') }}</b-button>
      </b-card-footer>
    </b-card>
    <b-card v-else>
      <b-card-title>{{ $t('messages.profile_removed') }}</b-card-title>
      <p>{{ $t('messages.your_profile_is_scheduled_for_removal') }}</p>
      <b-card-footer class="d-flex justify-content-between">
        <b-link :to="{name: '/'}">{{ $t('buttons.go_to_home_page') }}</b-link>
      </b-card-footer>
    </b-card>
  </b-container>
</template>

<script>
import {BContainer, BCard, BCardTitle, BCardFooter, BLink, BButton} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import {initialAbility} from "@/libs/acl/config";
import {EventBus} from "@/plugins/event-bus";

export default {
  components: {
    BContainer, BCard, BCardTitle, BCardFooter, BLink, BButton
  },
  name: "AccountRemoval",
  data() {
    return {
      profileRemoved: false
    }
  },
  methods: {
    confirmProfileRemoval() {
      this.$http.post('/user/remove-account', {
        id: this.$route.query.id,
        token: this.$route.query.token
      }).then(() => {
        this.profileRemoved = true
        this.logout()
      }).catch(error => {
        this.$notify().error(error.message)
      })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      EventBus.$emit('logout')
    }
  }
}
</script>